import Page from "components/pages/index/base/index";
import { NextPage } from "next";
import IArticle from "types/article";
import IPublication from "types/publication";


const IndexPage: NextPage = () => {
  return (
    <Page />
  );
};


export default IndexPage;

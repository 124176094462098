import { Box } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import React, { FC } from "react";

const Jumbotron = dynamic(
  import(
    /* webpackChunkName: "Jumbotron" */ "components/pages/index/base/jumbotron"
  )
);


const Page: FC = () => {
  return (
    <>
      <Box as="section">
        <Jumbotron />
      </Box>
    </>
  );
};

export default Page;
